// src/services/auth.js 
// Import a Base64 library, you can use a third-party library like 'btoa' and 'atob' or implement your own Base64 encoding/decoding functions.
// For this example, I'll use the built-in btoa and atob functions available in modern browsers.

// Function to set a cookie with the given name, value, and expiration days
function setCookie(name, value, days) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);

  // Convert the value to a JSON string
  const jsonValue = JSON.stringify(value);

  // Encode the JSON string using Base64 encoding
  const encodedValue = btoa(jsonValue);

  const cookieValue = encodedValue + (days == null ? '' : '; expires=' + expirationDate.toUTCString());
  document.cookie = name + '=' + cookieValue;
}

// Function to get the value of a cookie with the given name
function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      const cookieValue = cookie.substring(name.length + 1);
      
      // Decode the Base64 encoded value to get the original JSON string
      const decodedValue = atob(cookieValue);
      
      // Parse the JSON string to get the original object
      return JSON.parse(decodedValue);
    }
  }
  return null;
}
 
// Function to remove a cookie with the given name
function removeCookie(name) {
  setCookie(name, '', -1);
}
 
export function getEncodedCookie(name) { 
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      const cookieValue = cookie.substring(name.length + 1);
      return cookieValue;
    }
  }
  return null;
}

export function getSession() {
  const userSession = getCookie('user');
  return userSession ? JSON.parse(userSession) : null;
}

export function setSession(data) {
  setCookie('user', JSON.stringify(data), 700); // Update the 'user' cookie with a 700-day expiration
}

export function logout() {
  removeCookie('user'); // Remove the 'user' cookie to log out
}

