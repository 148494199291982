import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { getSession, logout, setSession } from './services/auth';  
import './App.css';

// Lazy load the components
const SignIn = lazy(() => import('./components/SignIn'));
const Welcome = lazy(() => import('./components/Welcome'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('./components/CookiePolicy'));
const Articles = lazy(() => import('./components/Articles'));
const Article = lazy(() => import('./components/Article'));
 
function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => { 
    const storedUser = getSession();
    if (storedUser) {
      setUser(storedUser);
    }
    setLoading(false);
  }, []);

  useEffect(() => { 
  }, [location]);

  const handleLogIn = (data) => {
    const { sessionId, id, name, gender, age, here, languages } = data;
    const user = { sessionId, id, name, gender, age, here, languages };
    setUser(user);
    setSession(user);
 
  };

  const handleLogOut = () => {
    logout();
    setUser(null);
 
    setTimeout(() => {
      window.location.href = '/';
    }, 300);
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={
          <div>
            {loading ? (
              <div>Loading...</div>
            ) : user ? (
              <Welcome user={user} onLogOut={handleLogOut} />
            ) : (
              <SignIn onSignIn={handleLogIn} />
            )}
          </div>
        } />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/online" element={<Welcome user={user} onLogOut={handleLogOut} />} />
        <Route path="/random" element={<Welcome user={user} onLogOut={handleLogOut} />} />
        <Route path="/messages" element={<Welcome user={user} onLogOut={handleLogOut} />} />
        <Route path="/current/:name" element={<Welcome user={user} onLogOut={handleLogOut} />} />
        <Route path="/article" element={<Articles />} />
        <Route path="/articles/:slug" element={<Article />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}

export default App;
